import { getCurrentInstance } from "@vue/composition-api";

const useProxy = () => {
  const { proxy } = getCurrentInstance();

  return {
    can: (action, subject) => (proxy.$can ? proxy.$can(action, subject) : true),
    route: proxy.$route,
    router: proxy.$router,
    store: proxy.$store,
  };
};

export default useProxy;
