import isEqual from "lodash/isEqual";

import useProxy from "@/hooks/useProxy";

const useQueryString = () => {
  const { route, router, store } = useProxy();

  store.dispatch("common/initializeQueryString", route.query);

  const addQueryStringParam = async (params, anchor = undefined) => {
    const { param, value } = params;
    const urlValue = getUrlValue(params);
    const storedValue = getStoredValue(params);
    const isQueryStringUpToDate =
      store.getters["common/getQueryStringIsUpToDate"] === true;
    if (
      !isQueryStringUpToDate ||
      (isEqual(urlValue, value) && isEqual(storedValue, value))
    ) {
      return;
    }

    if (anchor && anchor.charAt(0) !== "#") {
      anchor = `#${anchor}`;
    }

    await store.dispatch("common/addQueryStringParam", {
      param,
      value: Array.isArray(value) ? value.join(",") : String(value ?? ""),
    });

    if (store.getters["common/getQueryStringIsUpToDate"] === false) {
      const newQueryString = store.getters["common/getQueryString"];
      await store.dispatch("common/setQueryStringInitialized");
      router.replace({
        ...route,
        query: newQueryString,
        hash: anchor ?? "",
      });
    }
  };

  const waitUntilInitializedFromUrl = async (
    param,
    maxRetries = 5,
    checkInterval = 100
  ) => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        const condition =
          (await store.dispatch("common/isInitializedFromUrl", param)) === true;

        if (!condition) {
          return;
        }

        clearInterval(interval);
        resolve();
      }, checkInterval);

      setTimeout(() => {
        clearInterval(interval);
        reject();
      }, checkInterval * maxRetries);
    });
  };

  const setParamAsInitializing = async (param) =>
    await store.dispatch("common/setInitializedFromUrl", {
      param,
      status: false, // "on-going"
    });

  const setParamAsInitialized = async (param) =>
    await store.dispatch("common/setInitializedFromUrl", {
      param,
      status: true, // "done"
    });

  const setStoreFromUrlParam = async (
    dispatcher,
    param,
    value,
    dependsOn = undefined
  ) => {
    const callback = async () => {
      if (dispatcher) {
        await setParamAsInitializing(param);

        await store.dispatch(dispatcher, value);
      }

      await setParamAsInitialized(param);
    };

    if (dependsOn) {
      await waitUntilInitializedFromUrl(dependsOn, 15)
        .then(async () => await callback())
        .catch((error) => {
          console.warn(
            `Can't initialize ${param}: ${dependsOn} has never initialized!`,
            error
          );
        });
    } else {
      await callback();
    }
  };

  const getBooleanValue = (val) => {
    return ["true", "1"].includes(val);
  };

  const sanitizeUrlValue = (params, value) => {
    const {
      is_multiple = false,
      is_integer = false,
      is_boolean = false,
    } = params;

    let sanitizedValue = value;
    if (is_multiple) {
      sanitizedValue = value ? value.split(",") : [];
      if (sanitizedValue.length > 0) {
        if (is_integer) {
          sanitizedValue = sanitizedValue.map((id) => parseInt(id));
        } else if (is_boolean) {
          sanitizedValue = sanitizedValue.map((val) => getBooleanValue(val));
        }
      }
    } else if (value) {
      if (is_integer) {
        sanitizedValue = parseInt(value);
      } else if (is_boolean) {
        sanitizedValue = getBooleanValue(value);
      }
    }

    return sanitizedValue;
  };

  const getStoredValue = (params) => {
    const { param } = params;

    const storedQueryString = store.getters["common/getQueryString"];
    let storedValue = null;
    if (param in storedQueryString) {
      storedValue = storedQueryString[param];
      storedValue = sanitizeUrlValue(params, storedValue);
    }

    return storedValue;
  };

  const getUrlValue = (params) => {
    const { param } = params;

    let urlValue = null;
    if (param in route.query) {
      urlValue = route.query[param];
      urlValue = sanitizeUrlValue(params, urlValue);
    }

    return urlValue;
  };

  const synchronizeFilterWithQueryString = async (params) => {
    const {
      dispatcher,
      param,
      // value,
      // is_multiple = false,
      // is_integer = false,
      // is_boolean = false,
      dependsOn = undefined,
    } = params;
    const urlValue = getUrlValue(params);

    if (urlValue !== null) {
      await setStoreFromUrlParam(dispatcher, param, urlValue, dependsOn);
    } else {
      await setParamAsInitialized(param);
      await addQueryStringParam(params);
    }
    return urlValue;
  };

  return {
    addQueryStringParam,
    getUrlValue,
    synchronizeFilterWithQueryString,
  };
};

export default useQueryString;
